import React from "react";

function Gear() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="40"
            fill="none"
            viewBox="0 0 41 40"
        >
            <path
                fill="#442293"
                d="M34.31 26.859a1.735 1.735 0 00.177 1.657c.364.545.675 1.123.928 1.728a2.92 2.92 0 01-.623 3.244L33.5 34.781a2.898 2.898 0 01-3.245.623 10.146 10.146 0 01-1.727-.929 1.739 1.739 0 00-1.658-.176l-1.598.67a1.708 1.708 0 00-1.046 1.281 11.03 11.03 0 01-.577 1.893A2.91 2.91 0 0120.922 40H19.09a2.91 2.91 0 01-2.727-1.857 11.03 11.03 0 01-.576-1.893 1.709 1.709 0 00-1.046-1.28l-1.599-.67a1.739 1.739 0 00-1.657.175c-.545.365-1.123.676-1.728.929a2.9 2.9 0 01-3.244-.623l-1.293-1.293a2.922 2.922 0 01-.623-3.244c.253-.605.564-1.183.929-1.728.337-.486.404-1.111.176-1.657l-.658-1.599a1.746 1.746 0 00-1.293-1.046 11.025 11.025 0 01-1.893-.576A2.904 2.904 0 010 20.91v-1.834a2.91 2.91 0 011.857-2.727c.611-.249 1.245-.438 1.893-.564a1.762 1.762 0 001.293-1.058L5.7 13.13a1.714 1.714 0 00-.176-1.646 10.692 10.692 0 01-.929-1.74A2.925 2.925 0 015.219 6.5l1.293-1.292a2.92 2.92 0 013.244-.624c.605.258 1.183.573 1.728.94.49.33 1.112.391 1.657.165l1.599-.658a1.746 1.746 0 001.046-1.293c.135-.647.328-1.28.576-1.892A2.894 2.894 0 0119.09 0h1.834a2.898 2.898 0 012.727 1.846c.248.612.44 1.245.576 1.892.108.58.501 1.066 1.046 1.293l1.599.658a1.764 1.764 0 001.657-.164 10.312 10.312 0 011.728-.94 2.923 2.923 0 013.244.622L34.793 6.5a2.928 2.928 0 01.623 3.245c-.256.607-.566 1.19-.929 1.74a1.713 1.713 0 00-.176 1.645l.658 1.599c.226.547.712.944 1.293 1.057.648.126 1.281.315 1.893.565a2.907 2.907 0 011.856 2.726v1.834a2.904 2.904 0 01-1.856 2.727c-.613.248-1.246.441-1.893.576a1.746 1.746 0 00-1.293 1.046l-.659 1.599zm-5.465-6.865a8.84 8.84 0 10-8.84 8.84 8.848 8.848 0 008.84-8.839z"
            ></path>
        </svg>
    );
}

export default Gear;
