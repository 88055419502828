import React, { ReactElement } from "react";
import colors from "@styles/variables/contentintelligenceVariables.module.scss";
import styles from "./styles.module.scss";
import Pointing from "../svgs/pointing";
import Squares from "../svgs/squares";
import ArrowUp from "../svgs/arrowUp";
import Gear from "../svgs/gear";

export default function MakeItEasy(): ReactElement {
    const list = [
        {
            title: "Work Smarter with Optimized Processes",
            points: [
                "Accelerate Transformation with a No-Code Process Designer",
                "Digitize and Automate Form Submissions",
                "Gain Actionable Business Intelligence",
            ],
            icon: <Gear />,
        },
        {
            title: "Manage All Stages of Your Content",
            points: [
                "Centralize Your Enterprise Content",
                "Connect and Collaborate with Teammates and Customers",
                "Manage the Full Records Lifecycle",
            ],
            icon: <Squares />,
        },
        {
            title: "Make Content Easily Accessible and Searchable",
            points: [
                "Accurately Capture Invoice Information with A.I.",
                "Streamline High-Volume Capture",
                "Quickly Find Content",
            ],
            icon: <Pointing />,
        },
        {
            title: "Unify the Systems Your Teams Use Every Day",
            points: [
                "Automatically Sync Your Data with Direct Integrations",
                "Create Custom Integrations that Fit Your Business",
                "Integrate Legacy Systems with RPA",
            ],
            icon: <ArrowUp />,
        },
    ];

    return (
        <section className="flex flex-col mt-10 lg:mt-16 mb-10">
            <h2 className="text-center capitalize text-xl lg:text-4xl mb-14">
                we make it easy for you
            </h2>
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
                {list.map((item, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-start gap-8 p-8 md:p-16 rounded-sm"
                        style={{
                            boxShadow: "0px 0px 17px 1px rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <div className="flex items-center gap-4">
                            <div>{item.icon}</div>
                            <h3
                                style={{
                                    color: colors.purple,
                                }}
                                className="text-lg md:text-2xl"
                            >
                                {item.title}
                            </h3>
                        </div>
                        <ul className={styles.list}>
                            {item.points.map((point, index) => (
                                <span
                                    style={{
                                        color: "#565656",
                                    }}
                                    className="flex gap-3 font-light"
                                    key={index}
                                >
                                    {"•"}
                                    <li key={index} className="">
                                        {point}
                                    </li>
                                </span>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </section>
    );
}
