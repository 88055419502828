import React from "react";

function Squares() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 40 40"
        >
            <path
                fill="#442293"
                d="M18.704 26.593v8.11A5.297 5.297 0 0113.407 40h-8.11A5.307 5.307 0 010 34.703v-8.11a5.298 5.298 0 015.297-5.297h8.11a5.297 5.297 0 015.297 5.297zM13.407 0h-8.11A5.307 5.307 0 000 5.297v8.12a5.307 5.307 0 005.297 5.297h8.11a5.296 5.296 0 005.297-5.297v-8.12A5.297 5.297 0 0013.407 0zm21.286 21.295h-8.11a5.298 5.298 0 00-5.297 5.297v8.11A5.307 5.307 0 0026.583 40h8.11a5.296 5.296 0 005.297-5.297v-8.11a5.298 5.298 0 00-5.297-5.297zM22.615 6.568l1.349-2.465c.549-.135 1.79-.26 2.167.135l2.032-1.243c-.183-.51.51-1.56.876-1.983l2.813-.068c.394.414 1.126 1.425.963 1.946l2.1 1.146c.346-.414 1.598-.346 2.157-.231l1.464 2.398c-.164.54-.674 1.686-1.204 1.81l.048 2.38c.54.096 1.108 1.213 1.291 1.753l-1.348 2.465c-.559.135-1.801.26-2.167-.135l-2.042 1.243c.183.51-.501 1.56-.877 1.984l-2.802.067c-.395-.414-1.127-1.425-.973-1.945l-2.09-1.147c-.356.414-1.599.347-2.167.241l-1.454-2.398c.164-.55.674-1.695 1.204-1.82l-.058-2.38c-.53-.096-1.099-1.213-1.282-1.753zm6.83 4.98h-.001a2.5 2.5 0 003.586-2.903 2.496 2.496 0 00-4.588-.487 2.494 2.494 0 001.002 3.39z"
            ></path>
        </svg>
    );
}

export default Squares;
