import React from "react";

function ArrowUp() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="40"
            fill="none"
            viewBox="0 0 30 40"
        >
            <path
                fill="#442293"
                fillRule="evenodd"
                d="M17.467 10.055V22.96c0 1.986-.788 3.89-2.192 5.294L4.26 39.269A2.495 2.495 0 11.73 35.74l11.015-11.015a2.496 2.496 0 00.731-1.765V10.056h-6.55a.749.749 0 01-.521-1.287L14.105.35a1.247 1.247 0 011.735 0l8.7 8.418a.749.749 0 01-.521 1.287h-6.552zm.731 21.727a2.495 2.495 0 113.53-3.529l7.485 7.486a2.495 2.495 0 11-3.529 3.529l-7.486-7.486z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default ArrowUp;
