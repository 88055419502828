import React from "react";

function Pointing() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="40"
            fill="none"
            viewBox="0 0 31 40"
        >
            <path
                fill="#442293"
                d="M27.757 22.196a.606.606 0 00.043-.102 3.76 3.76 0 00-.648-4.444 3.851 3.851 0 00-1.158-.816 3.757 3.757 0 00-.615-4.494 4.4 4.4 0 00-1.577-1.002l5.521-5.24.012-.011a4.197 4.197 0 001.255-2.348 3.188 3.188 0 00-.891-2.756A3.136 3.136 0 0027.419 0a3.99 3.99 0 00-2.74 1.204l-14.451 12.92a3.832 3.832 0 01-.365.296c.277-2.191.13-9.666-3.28-10.966-1.625-.62-3.26-.204-4.252 1.25-.79 1.16-1.227 2.905-1.334 5.336l-.97 16.735a9.964 9.964 0 00.692 4.448c.472 1.092 2.42 4.892 7.646 7.951 1.562.915 3.962 1.17 5.58.25 4.816-2.733 14.678-10.68 15.095-11.018.031-.025.061-.051.09-.08a3.71 3.71 0 00-.039-5.238 3.89 3.89 0 00-1.334-.893z"
            ></path>
        </svg>
    );
}

export default Pointing;
